@import '../../scss/variables.scss';

.footer {
  padding: 90px 65px 60px;
  background-color: $headerBgColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .footerContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto;
    .group1,
    .group2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .groupContainer {
        display: grid;
        gap: 25px;
        .footerLink {
          color: $textColorLight;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-decoration: none;
        }
      }
    }
    .group1 {
      grid-area: group1;
    }
    .group2 {
      grid-area: group2;
    }
    .socials {
      grid-area: socials;
      display: grid;
      grid-template-rows: 1fr;
      align-items: center;
      justify-content: center;
      padding: 110px 0 0;
      width: 100%;
      h3 {
        color: $textColorLight;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
        padding: 0;
        text-align: center;
        order: 1;
      }
      p {
        padding: 0;
        margin: 0;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 275;
        line-height: normal;
        text-align: center;
        color: $textColorLight;
        order: 1;
      }
      .linksContainer {
        padding-top: 26px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        order: 1;
      }
      .logoContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        order: 1;
        .logo {
          width: 100%;
          max-width: 298px;
          padding-top: 53px;
        }
      }
      .termsP {
        // padding: 0;
        // margin: 0;
        padding-top: 64px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        order: 3;

        button {
          background-color: transparent;
          border: none;
          color: $primaryColor;
          text-decoration: underline;
        }
      }
      .copyright,
      .copyright1,
      .copyright2 {
        order: 4;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $textColorLight;
      }
      .copyright1 {
        padding-top: 24px;
      }
      .copyright {
        display: none;
      }
      .sectigoContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 52px;
        width: 100%;
        order: 5;
        .sectigo {
          width: 139px;
        }
      }
    }
    grid-template-areas:
      'group1 . group2'
      'socials socials socials';
  }
  @include smallBreakpoint {
  }
  @include largeBreakpoint {
    padding: 37px $desktopPadding 28px;
    .footerContainer {
      grid-template-columns: auto 1fr auto;
      grid-template-rows: auto;
      grid-template-areas: 'group1 socials group2';

      .socials {
        padding-top: 0;
        .logoContainer {
          order: 0;
          .logo {
            padding: 0;
          }
        }
        h3 {
          padding-top: 53px;
        }
        .linksContainer {
          padding-top: 5px;
        }
        .copyright1,
        .copyright2 {
          display: none;
        }
        .copyright {
          display: block;
          order: 3;
          padding-top: 58px;
        }
        .termsP {
          order: 4;
          padding-top: 9px;
        }
        .sectigoContainer {
          padding-top: 37px;
        }
      }
    }
  }
  @include xLargeBreakpoint {
    .footerContainer {
      max-width: $maxContentWidth;
    }
  }
}
