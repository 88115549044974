@import '../../scss/variables.scss';

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 $mobileRightHeaderPadding 0 $mobilePadding;
  background-color: $headerBgColor;
  position: relative;
  .navbarContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    .mobileShow {
      height: $headerMobileHeight;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        width: 300px;
      }
      .navbarToggler {
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: $textColorLight;
        border-radius: 6px;
        width: 48px;
        height: 48px;
        font-size: 26px;
        flex-shrink: 0;
        .close {
          display: inline-block;
        }
        .hamburger {
          display: none;
        }
        &.collapsed {
          font-size: 26px;
          .close {
            display: none;
          }
          .hamburger {
            display: inline-block;
          }
        }
      }
    }
    .navLinksContainer {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 10;
      background-color: $headerBgColor;
      &.collapsed {
        display: none;
      }
      padding-bottom: 20px;
      .links {
        .authNLngContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
        }
      }
    }
  }

  @include smallBreakpoint {
  }

  @include largeBreakpoint {
    .navbarContainer {
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .mobileShow {
        height: $headerHeight;
        .navbarToggler {
          display: none;
        }
      }
      .navLinksContainer,
      .navLinksContainer.collapsed {
        display: flex;
        position: static;
        align-items: center;
        justify-content: center;
        padding-bottom: 0;
        .links {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 50px;
        }
      }
    }
  }

  @include xLargeBreakpoint {
    .navbarContainer {
      max-width: $maxContentWidth;
    }
  }
}
