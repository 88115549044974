//  Fonts import
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

//  Colours
$primaryColor: #27aae1;
$lightBackground: #d7dce2;
$bgColor: #ffffff;
$lightGrey: #6b6b6b;
$iceWhite: #f8f8f8;
$greyTextColor: #c9c9c9;
$matteWhite: #fefffe;
$textColorDark: #000000;
$textColorSemiDark: #3a2a2d;
$textColorDarkGrey: #475156;
$textColorLight: #ffffff;
$headerBgColor: #2a2c3a;
$modalBgColor: #2a2c3a;
$danger: #ed3237;
$greyBoderColor: #ced4da;
$languagePickerBgColor: #f4f6f8;
$extraDarkBlue: #040f15;

//  Fonts
$font: 'Poppins', sans-serif;
$specialFont: 'Montserrat', sans-serif;
$interFont: 'Inter', sans-serif;
$font-icon: 'Font Awesome 5 Free';

//  Spacing and Sizing
$mobilePadding: 45px;
$mobileRightHeaderPadding: 30px;
// $mobilePadding: 45px;
// $mobileRightHeaderPadding: 30px;
$mobilePadding: 25px;
$mobileRightHeaderPadding: 25px;
$tabletPadding: 70px;
$desktopPadding: 70px;
$headerMobileHeight: 80px;
$headerHeight: 130px;
$maxContentWidth: 1294px;

//  Breakpoints
$smallBreakpoint: 576px;
$mediumBreakpoint: 768px;
$largeBreakpoint: 992px;
$xLargeBreakpoint: 1200px;

//Mixins
// When it becomes tablet size
@mixin smallBreakpoint {
  @media (min-width: $smallBreakpoint) {
    padding-left: $tabletPadding;
    padding-right: $tabletPadding;
    @content;
  }
}

@mixin mediumBreakpoint {
  @media (min-width: $mediumBreakpoint) {
    @content;
  }
}

// When it becomes desktop size
@mixin largeBreakpoint {
  @media (min-width: $largeBreakpoint) {
    padding-left: $desktopPadding;
    padding-right: $desktopPadding;
    @content;
  }
}

// When it becomes xLarge size
@mixin xLargeBreakpoint {
  @media (min-width: $xLargeBreakpoint) {
    padding-left: $desktopPadding;
    padding-right: $desktopPadding;
    @content;
  }
}

// @include largeBreakpoint {content here}
