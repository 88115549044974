@import '../../scss/variables.scss';
.customCheckbox {
  border: solid 1px #d1d1d1;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background-color: transparent;
  color: $textColorLight;
  &.semiDark {
    color: $textColorSemiDark;
  }
}
