@import '../../scss/variables.scss';

.authenticatedUserHeader {
  position: relative;
  display: inline-block;
  .toggler {
    background-color: $primaryColor;
    border: none;
    width: 45px;
    height: 45px;
    color: $textColorLight;
    padding: 0;
    margin: 0;
    font-size: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .profileImg {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .dropdown {
    display: none;
    background-color: $headerBgColor;
    width: 227px;
    height: auto;
    position: absolute;
    top: calc(100% + 30px);
    left: 0;
    z-index: 3000;
    padding: 35px 33px;
    &.show {
      display: block;
    }
    .btnsContainer {
      display: grid;
      gap: 12px;
      .btn {
        padding: 0;
        background-color: transparent;
        color: $textColorLight;
        text-decoration: none;
        color: $textColorLight;
        border: none;
        text-align: left;
        border: none;
        font-size: 12px;
        text-transform: capitalize;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  @include largeBreakpoint {
    padding: 0;
    .dropdown {
      width: 150px;
      top: calc(100% + 50px);
    }
  }
}
