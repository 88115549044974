@import '../../scss/variables.scss';

.customButton {
  display: inline-flex;
  padding: 15px 40px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  &.blue {
    color: $textColorLight;
    background: $primaryColor;
  }
  &.white {
    background-color: $bgColor;
    color: $textColorDark;
    &.smallText {
      color: $textColorSemiDark;
    }
  }
  &.black {
    background-color: $headerBgColor;
    color: $textColorLight;
  }
  &.outlineWhite {
    background-color: $bgColor;
    color: $textColorDark;
    border: 1px solid $textColorDark;
    width: 100%;
    &:hover {
      border: 2px solid $textColorDark;
    }
  }
  &.smallText {
    text-align: center;
    font-family: $font;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    padding: 7px 8px;
    @include largeBreakpoint {
      padding-left: 0;
      padding-right: 0;
      font-size: 18px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  &:hover {
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.2);
    font-weight: 700;
  }
  &.pill {
    border-radius: 40px;
    &:hover {
      font-weight: 700;
      box-shadow: 4px 4px 4px 0px rgba(237, 226, 226, 0.25);
    }
  }
}
