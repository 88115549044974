.imagePickerContainer {
  position: relative;
  display: inline-block;

  .fileInput {
    display: none; // Hide the default input
  }

  .imageLabel {
    cursor: pointer;

    .imageCircle {
      width: 150px; // Adjust the size as needed
      height: 150px;
      border-radius: 50%;
      border: 2px solid #dcdcdc; // Light border
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      transition: border-color 0.3s ease;
      background-color: #f9f9f9;

      &:hover {
        border-color: #007bff; // Change on hover
      }

      .profileImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
      }

      .resizeOverlay {
        position: absolute;
        bottom: -10px;
        right: -10px;
        background-color: #007bff;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        cursor: nwse-resize;
      }

      .dragOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        display: none; // Hidden by default
        cursor: move;
      }

      &:hover .dragOverlay {
        display: block; // Show on hover
      }

      .editOverlay {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 20px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 12px;
        color: #333;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        transition: opacity 0.3s ease;

        svg {
          font-size: 12px;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  /* Additional styles for resizing & repositioning */
  .resizableHandle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-color: rgba(0, 123, 255, 0.6);
    cursor: nwse-resize;
    border-radius: 50%;
  }

  .draggableHandle {
    cursor: move;
  }
}
