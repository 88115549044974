@import '../../scss/variables.scss';
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0;

  .loginLink {
    font-size: 14px;
    font-family: $specialFont;
    color: $primaryColor;
    padding: 0;
    margin: 0;
    font-weight: 700;
    text-decoration: none;
  }

  .signUpLink {
    display: inline-flex;
    height: 41.5px;
    padding: 15px 25px;
    align-items: center;
    gap: 15px;
    flex-shrink: 0;
    color: $textColorLight;
    background-color: $primaryColor;
    font-size: 16px;
    font-weight: 500;
    border-radius: 40px;
    border: none;
    line-height: normal;
  }
}
