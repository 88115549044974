@import '../../scss/variables.scss';
.googlePlacesInput {
  .label {
    font-weight: 500;
    color: $textColorSemiDark;
  }
  input {
    margin-top: 6px;
    width: 100%;
    height: 39px;
    flex-shrink: 0;
    font-family: $interFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    border: 1px solid $lightGrey;
    &.error {
      border: 2px solid $danger;
    }
    &.semiDark {
      color: $textColorSemiDark;
      font-weight: 500;

      input {
        color: $textColorSemiDark;
      }
    }
  }
}
