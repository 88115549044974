@import '../../../scss/variables.scss';

.contactInfo {
  padding-top: 25px;
  padding-bottom: 46px;
  .contactInfoContainer {
    .titleContainer {
      h2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: $textColorSemiDark;
      }
      p {
        color: $textColorDark;
        font-size: 11px;
        font-weight: 300;
        line-height: normal;
      }
    }
    .form {
      padding-top: 24px;
      .imgContainer {
        h4 {
          color: $textColorSemiDark;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 21px;
          margin-bottom: 17px;
        }
        .tempImage {
          margin-left: 37px;
          width: 82px;
          height: 82px;
          border-radius: 50%;
          overflow: hidden;
          position: relative;
          background-color: $greyTextColor;
          border: solid 1px $lightGrey;
        }
      }
      section {
        h3 {
          color: $textColorSemiDark;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      .basicInfo,
      .addressContainer {
        max-width: 605px;
      }
      .basicInfo {
        padding-top: 45px;
        display: flex;
        flex-direction: column;
        .nameContainer {
          display: flex;
          flex-direction: column;
          margin-top: 16px;
          gap: 16px;
          width: 100%;
        }
        .phoneContainer {
          display: flex;
          flex-direction: column;
          margin-top: 16px;
          width: 100%;
        }
      }
      .addressContainer {
        margin-top: 81px;
        display: flex;
        flex-direction: column;
        // gap: 52px;
        width: 100%;
        h3 {
          margin-bottom: 20px;
        }

        .shippingAddressCheckbox {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          margin-top: 16px;
          margin-bottom: 52px;
          label {
            color: $textColorSemiDark;
            font-family: $interFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
      .btnContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        padding-top: 24px;
        width: 130px;
      }
    }
  }

  @include mediumBreakpoint {
    .contactInfoContainer {
      .titleContainer {
        h2 {
          font-size: 40px;
        }
        p {
          color: $textColorDark;
          font-size: 16px;
          font-weight: 400;
        }
      }

      .form {
        section {
          h3 {
            font-size: 20px;
            font-weight: 600;
          }
        }
        .imgContainer {
        }
        .basicInfo {
          .nameContainer {
            flex-direction: row;
            gap: 14px;
            margin-top: 19px;
          }
          .phoneContainer {
            max-width: 297px;
            margin-top: 25px;
          }
        }
        .addressContainer {
          h3 {
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}
