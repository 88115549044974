@import '../../scss/variables.scss';

.keepMeSignAndForgot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0;
  margin: 0;
  flex-shrink: 0;
  color: $textColorLight;
  font-size: 16px;
  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-family: $interFont;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 10px;
    input {
      padding: 0;
      margin: 0;
      height: 18px;
      width: 18px;
      border: none;
      outline: none;
      background-color: transparent;
    }
  }
  .forgotLink {
    color: $primaryColor;
    text-decoration: none;
    font-family: $interFont;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: inline-flex;
    justify-content: flex-end;
    width: 100%;
    text-align: left;
  }
}
