@import '../../scss/variables.scss';

.home {
  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 58px $mobilePadding 77px;
    background: linear-gradient(rgba(42, 44, 58, 0.7), rgba(42, 44, 58, 0.7)),
      url('../../assets/default_event_banner.png') no-repeat center/cover;
    .bannerContainer {
      width: 100%;
      color: $textColorLight;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      h1 {
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }
      h4 {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .btnContainer {
        padding-top: 40px;
      }
    }
  }
  .promotersAndEvents {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .promoters {
      width: 100%;
      background-color: $primaryColor;
      margin-bottom: -50px;
      .promotersContainer {
        padding: 37px $mobilePadding 78px;
        width: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-end;
        .textContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          flex: 1;
        }
        .btnContainer {
          padding-top: 14px;
        }
      }
    }
    .logoContainer {
      height: 100px;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      .linksLogo {
        z-index: 11;
        width: 100px;
        transform: rotate(270deg);
        height: auto;
        self-align: center;
      }
    }
    .events {
      margin-top: -50px;
      width: 100%;
      padding: 74px $mobilePadding 70px;
      background-color: $headerBgColor;
      .textContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-end;

        flex: 1;
      }
      .btnContainer {
        padding-top: 14px;
      }
    }
    .promoters,
    .events {
      .textContainer {
        color: $textColorLight;
        font-style: normal;
        line-height: normal;
        h1 {
          font-size: 32px;
          font-weight: 800;
        }
        h4 {
          font-size: 20px;
          font-weight: 600;
          padding-top: 19px;
          &.list {
            padding-top: 4px;
          }
        }
        h6 {
          font-size: 16px;
          font-weight: 500;
          padding-top: 9px;
        }
      }
    }
  }
  .whyEventlinx {
    padding: 0 $mobilePadding 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    .explanations {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
      flex-wrap: wrap;
    }
    gap: 29px;
  }
  .phrase {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 84px $mobilePadding 36px;
    background-color: $headerBgColor;
    h2 {
      color: $textColorLight;
      line-height: normal;
      font-style: normal;
      font-size: 16px;
      font-weight: 600;
      padding-top: 44px;
      &.quote {
        font-size: 32px;
        font-weight: 800;
      }
    }
  }

  @include smallBreakpoint {
    padding: 0;
    .banner {
      padding-left: $tabletPadding;
      padding-right: $tabletPadding;
    }
    .promotersAndEvents {
      .promoters {
        padding-left: $tabletPadding;
        padding-right: $tabletPadding;
      }
      .events {
        padding-left: $tabletPadding;
        padding-right: $tabletPadding;
      }
    }
    .whyEventlinx {
      padding-left: $tabletPadding;
      padding-right: $tabletPadding;
    }
    .phrase {
      padding-left: $tabletPadding;
      padding-right: $tabletPadding;
    }
    .mailListContainer {
      padding-left: $tabletPadding;
      padding-right: $tabletPadding;
    }
  }
  @include largeBreakpoint {
    padding: 0;
    .banner {
      padding: 104px $desktopPadding;
    }
    .promotersAndEvents {
      flex-direction: row-reverse;
      align-items: stretch;
      .promoters {
        margin-bottom: 0;
        padding: 82px $desktopPadding;
        min-height: 100%;
        margin-left: -59px;
        justify-content: flex-end;
        .textContainer {
          max-width: 481px;
        }
        .btnContainer {
          max-width: 481px;
          display: flex;
          width: 100%;
          justify-content: flex-end;
        }
      }
      .logoContainer {
        height: auto;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        .linksLogo {
          z-index: 11;
          width: 118px;
          transform: rotate(0deg);
          transform: translateY(-90px);
          height: auto;
          self-align: center;
        }
      }
      .events {
        margin-right: -59px;
        margin-top: 0;
        padding: 82px $desktopPadding;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .textContainer {
          align-items: flex-start;
          max-width: 481px;
        }
        .btnContainer {
          max-width: 481px;
          display: flex;
          width: 100%;
          justify-content: flex-end;
        }
      }
    }
    .whyEventlinx {
      padding-left: $desktopPadding;
      padding-right: $desktopPadding;
      .explanations {
        // align-items: flex-start;
        justify-content: space-around;
        max-width: 1227px;
      }
    }
    .phrase {
      padding-left: $desktopPadding;
      padding-right: $desktopPadding;
    }
  }
  @include xLargeBreakpoint {
    padding: 0;
    .banner {
      .bannerContainer {
        max-width: $maxContentWidth;
      }
    }
    .promotersAndEvents {
      .promoters {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        .promotersContainer {
          width: 100%;
          padding-left: 80px;
          max-width: calc((#{$maxContentWidth} - 118px) / 2);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
      }
      .events {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;
        .eventsContainer {
          width: 100%;
          padding-right: 80px;
          max-width: calc((#{$maxContentWidth} - 118px) / 2);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
      }
    }
  }
}
